import React from 'react'

import Notifications from '../../components/views/settings/notifications'
import { LayoutLoggedIn } from '../../components/views/layout'

export default function NotificationsPage() {
   return (
      <LayoutLoggedIn>
         <Notifications />
      </LayoutLoggedIn>
   )
}
