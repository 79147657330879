import React, { useMemo } from 'react'

import SettingsItem from '../../common/views/settingsItem/settingsItem'
import { Alert, Button, LoadingOverlay, PageHeader } from '../../common'
import { useSettings, useSettingsItems } from '../../../hooks'
import { isNumeric } from '../../../common/utils/validators'
import { Severity } from '../../../types'

import { CustomFieldMapper, parseSettingsObject } from './helpers'
import { isEmptyObj } from '../../common/form/helper'

const Notifications = () => {
   const {
      error: settingsError,
      isLoading,
      isSuccess,
      isUpdating,
      settings,
      update: updateSettings,
   } = useSettings({ type: 'notifications' })
   const {
      changedData,
      errors: customFieldErrors,
      isSubmitted,
      onChangeCustomField,
      onChangeToggle,
      onValidate,
   } = useSettingsItems()
   const handleSave = () => {
      const isValid = onValidate(settings, customFields)

      if (isValid) {
         // so apparently in order to update 'AllowPushNotifications' and 'NotifyDays' in the post request,
         // we don't use those properties, we use 'ReceiveNotifications' and 'NumberOfDaysBeforeNotification'
         // but these properties aren't given to us in the GET request for settings... go figure!
         const ReceiveNotifications =
            changedData?.AllowPushNotifications ||
            settings.AllowPushNotifications
         const NumberOfDaysBeforeNotification =
            changedData?.NotifyDays || settings.NotifyDays

         updateSettings({
            ...settings,
            ...changedData,
            NumberOfDaysBeforeNotification,
            ReceiveNotifications,
         })
      }
   }

   const allowedSettings = ['AllowPushNotifications']

   const customFields: CustomFieldMapper = {
      AllowPushNotifications: {
         key: 'NotifyDays',
         label: 'Number of Days Before Notification',
         type: 'number',
         validate: (key, value) => {
            const isANumber = isNumeric(key, value)
            if (isANumber) return isANumber

            return parseInt(value) < 1
               ? {
                    key,
                    error: 'This field needs to be greater than 0',
                 }
               : null
         },
      },
   }

   const parsedSettings = useMemo(
      () => parseSettingsObject(settings, allowedSettings, customFields),
      [settings]
   )

   const renderErrorMessage = useMemo(() => {
      if (!isSubmitted) return null

      if (!isEmptyObj(customFieldErrors)) {
         return 'There are errors with your custom fields, see above'
      }

      return settingsError
   }, [isSubmitted, customFieldErrors, settingsError])

   return (
      <>
         <PageHeader title="Notifications" variant="wideMargin" />

         <p>
            If Receive Notifications is enabled, you will receive any date
            sensitive notifications, such as:
         </p>
         <ul>
            <li>Tool Due Back Date</li>
            <li>Warranty Expiration</li>
            <li>Rental Tool Due Back Date</li>
            <li>Custom Date Fields</li>
            <li>Due Date Reminder</li>
         </ul>
         <p className="extra-margin">
            Determines the number of dates before the notification date that a
            message is generated.
         </p>

         <hr className="extra-margin" />

         {isLoading ? (
            <LoadingOverlay />
         ) : (
            <>
               {parsedSettings &&
                  parsedSettings.map((item) => {
                     return (
                        <SettingsItem
                           key={item.id}
                           customField={item?.customField}
                           description={item.description}
                           descriptionLocation={item?.customField?.position}
                           error={customFieldErrors[item?.customField?.key]}
                           id={item.id}
                           isEnabled={
                              changedData.hasOwnProperty(item.id)
                                 ? changedData[item.id]
                                 : item.value
                           }
                           label={item.name}
                           onChangeCustomField={onChangeCustomField}
                           onToggle={onChangeToggle}
                        />
                     )
                  })}

               {renderErrorMessage && <Alert message={renderErrorMessage} />}

               {isSuccess && (
                  <Alert
                     message="Notification settings updated successfully"
                     severity={Severity.SUCCESS}
                  />
               )}

               <Button loading={isUpdating} onClick={handleSave}>
                  Save
               </Button>
            </>
         )}
      </>
   )
}

export default Notifications
